<template>
	<div v-if="images && images.length">
		<!-- Slider -->
		<div v-if="images?.length > 1" ref="emblaRef" class="embla relative overflow-hidden">
			<div class="embla__container flex gap-[1px]">
				<NuxtLink
					v-for="(image, i) in images"
					:key="image.id"
					:to="`/products/${slug}`"
					no-prefetch
					class="embla__slide flex-[0_0_100%]"
				>
					<img
						:src="image.urlThumbnail || image.url"
						loading="lazy"
						:alt="image.altText || productName + ' image ' + i"
						:longdesc="image.description"
						class="mx-auto size-32 min-[390px]:size-36 min-[410px]:size-40 md:size-48"
					/>
				</NuxtLink>
			</div>
			<!-- Slider controls -->
			<button
				aria-label="Previous slide"
				class="absolute top-1/2 hidden lg:flex"
				@click="scroll(false)"
			>
				<ChevronLeftIcon class="size-4 text-zinc-400" />
			</button>
			<button
				aria-label="Next slide"
				class="absolute right-0 top-1/2 hidden lg:flex"
				@click="scroll(true)"
			>
				<ChevronRightIcon class="size-4 text-zinc-400" />
			</button>
		</div>
		<!-- Single image -->
		<NuxtLink v-else :to="`/products/${slug}`" no-prefetch>
			<img
				v-if="images.length === 1"
				:src="images[0].urlThumbnail || images[0].url"
				:alt="images[0].altText || productName"
				:longdesc="images[0].description"
				class="mx-auto size-32 min-[390px]:size-36 min-[410px]:size-40 md:size-48"
			/>
		</NuxtLink>
	</div>

	<!-- Fallback -->
	<NuxtLink v-else :to="`/products/${slug}`" no-prefetch>
		<ImagePlaceholder
			class="mx-auto size-32 min-[390px]:size-36 min-[410px]:size-40 md:size-48"
		/>
	</NuxtLink>
</template>

<script setup lang="ts">
import emblaCarouselVue from 'embla-carousel-vue';
import type { Image } from '~/types/app.types';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/outline';
import ImagePlaceholder from '../icons/ImagePlaceholder.vue';

defineProps<{ images: Image[] | null; slug: string; productName: string }>();

const [emblaRef, emblaApi] = emblaCarouselVue({ loop: true });

function scroll(forward: boolean) {
	if (!emblaRef.value) return;
	if (forward) {
		emblaApi.value?.scrollNext();
	} else {
		emblaApi.value?.scrollPrev();
	}
}
</script>
